@import "main";


.orcid {
	color: #A6CE39;
}

.googlescholar {
	color: #4788ee;
}

.brainlogo {
	color: #428bca;
}

ol.bibliography { list-style-type: none; }

.abstractindent {
  padding-left: 22px ;
} 


.map-container{
	overflow:hidden;
	padding-bottom:50%;
	position:relative;
	height:0;
}
.map-container iframe{
	left:0;
	top:0;
	height:100%;
	width:100%;
	position:absolute;
}